import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Header/_components/CharacterRegisterBtn.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Header/_components/CollaboRegisterBtn.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Header/BtnMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Header/HeaderLinks.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Header/MobileHeaderLinks.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Header/SheetProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/LanguageSwitcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LogoImage"] */ "/vercel/path0/app/_components/LogoImage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/RegisterHeader/img.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Shared/HeaderAvatar/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SheetTrigger","SheetContent","SheetClose"] */ "/vercel/path0/app/_components/ui/sheet.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_messenger/popup/PopupMessengerSheet.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.2_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/.pnpm/next@14.2.2_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
